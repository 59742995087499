import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearFilters } from "../redux/filters.slice";
import { closePopup } from "../utils/dom";

export const ClosePopupRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //close popup
    closePopup();

    //Clear search
    dispatch(clearFilters());
  }, []);
  return <div></div>;
};
