import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLatLng } from "../redux/filters.slice";

export const CloserBranchStoresButton = ({ className, onClick }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOnClick = () => {
    try {
      //Set loading
      setLoading(true);

      //Get current position
      navigator.geolocation.getCurrentPosition((position) => {
        //Set loading
        setLoading(false);

        //Load to global filters obj lat and lng
        dispatch(
          setLatLng({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );

        //Check onClick and dispatch it
        if (onClick) {
          onClick();
        }
      }, handleCurrentPositionError);
    } catch (error) {
      //Set loading
      setLoading(false);

      //Ops geolocation not available
      handleCurrentPositionError();
    }
  };

  const handleCurrentPositionError = () => {
    //Clear lat lng in global filters object
    dispatch(setLatLng({ latitude: "", longitude: "" }));

    //Check onClick and dispatch it
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`${className} c-rounded-btn c-rounded-btn--icon`}
      disabled={loading}
      onClick={handleOnClick}
    >
      <span className="c-rounded-btn__icon icon-target"></span>
      {loading ? "Buscando..." : "Buscar local más cerca"}
    </button>
  );
};
