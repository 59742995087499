import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLatLng } from "../redux/filters.slice";

export const CloserBranchStoresLink = ({ onClick }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOnClick = () => {
    try {
      if(loading) { return; }
      //Set loading
      setLoading(true);

      //Get current position
      navigator.geolocation.getCurrentPosition((position) => {
        //Set loading
        setLoading(false);

        //Load to global filters obj lat and lng
        dispatch(
          setLatLng({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );

        //Check onClick and dispatch it
        if (onClick) {
          onClick();
        }
      }, handleCurrentPositionError);
    } catch (error) {
      //Set loading
      setLoading(false);

      //Ops geolocation not available
      handleCurrentPositionError();
    }
  };

  const handleCurrentPositionError = () => {
    //Clear lat lng in global filters object
    dispatch(setLatLng({ latitude: "", longitude: "" }));

    //Check onClick and dispatch it
    if (onClick) {
      onClick();
    }
  };

  return (
    <a 
      onClick={handleOnClick}
      style={{ "fontSize": "2.5em" }}
      className="c-shows-heading__link c-arrow-link">
        {loading ? "Buscando local más cercano..." : "Buscar local más cercano"}
    </a>
  );
};
