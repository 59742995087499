import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFocusResult, setHoverResult } from "../shared/redux/search.slice";

export const BranchStorePin = ({ item }) => {
  const hoverItem = useSelector((state) => state.search.hoverResult);
  const focusResult = useSelector((state) => state.search.focusResult);
  const dispatch = useDispatch();
  const classes = ["c-locator-map__marker"];

  //Check classes
  if (hoverItem?.number === item.number) {
    classes.push("is-focus");
  }
  if (hoverItem && hoverItem?.number !== item.number) {
    classes.push("is-focusing");
  }
  if (focusResult && focusResult?.number !== item.number) {
    classes.push("d-mobile-none");
  }

  const handleMouseOver = () => {
    dispatch(setHoverResult(item));
  };
  const handleMouseOut = () => {
    dispatch(setHoverResult(null));
  };
  const handleClick = () => {
    dispatch(setFocusResult(item));
  };

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
      className={classes.join(" ")}
      style={{ left: "-17px", top: "-42px" }}
      data-open={item.isOpen}
    ></div>
  );
};
