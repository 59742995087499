import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../redux/filters.slice";

export const LocationsSelect = () => {
  const locations = useSelector((state) => state.search.locations);
  const location = useSelector((state) => state.filters.location);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    dispatch(setLocation(e.target.value));
  };

  return (
    <label className={`c-branch-form__group c-branch-form__group--select ${location ? "" : "is-placeholder"}`}>
      <select name="ciudad" className="c-branch-form__select" value={location} onChange={handleOnChange}>
        <option defaultValue disabled={!!location}>
          Seleccione el barrio...
        </option>
        {locations?.map((loc) => (
          <option key={loc.name} value={loc.name}>
            {loc.name}
          </option>
        ))}
      </select>

      <span className="c-branch-form__label">Barrio / Localidad</span>
      <span className="c-branch-form__input">{location ? location : "Seleccione el barrio..."}</span>
    </label>
  );
};
