import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayMap, setFocusResult } from "../shared/redux/search.slice";

export const SearchMenuMobile = ({ setShowFiltersPopup }) => {
  const filtersCount = useSelector((state) => state.filters.filtersCount);
  const displayMap = useSelector((state) => state.search.displayMap);
  const dispatch = useDispatch();

  const handleChangeDisplayMap = (state) => {
    dispatch(setFocusResult(null));
    dispatch(setDisplayMap(state));
  };

  return (
    <div className="c-locator-filters__aligner o-align-middle__aligner">
      <button
        type="button"
        className="c-locator-filters__btn"
        data-count={filtersCount ? filtersCount : undefined}
        onClick={() => setShowFiltersPopup(true)}
      >
        <span className="c-locator-filters__btn-icon icon-filter"></span> Filtrar{" "}
      </button>{" "}
      <button
        type="button"
        onClick={() => handleChangeDisplayMap(true)}
        className={`c-locator-filters__btn c-locator-filters__btn--hidable ${!displayMap ? "is-active" : ""}`}
      >
        <span className="c-locator-filters__btn-icon icon-pin"></span>
        Ver en mapa
      </button>
      <button
        type="button"
        onClick={() => handleChangeDisplayMap(false)}
        className={`c-locator-filters__btn c-locator-filters__btn--hidable ${displayMap ? "is-active" : ""}`}
      >
        <span className="c-locator-filters__btn-icon icon-list"></span>
        Ver listado
      </button>
    </div>
  );
};
