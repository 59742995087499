import React from "react";

export const BranchStoresSkeletonLoader = () => {
  return (
    <ul className="c-locator-skeleton__list js-locator-skeleton">
      <li className="c-locator-list__item c-locator-skeleton__item">
        <div className="c-locator-skeleton__info">
          <div className="c-locator-skeleton__name c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__name c-locator-skeleton__name--mobile c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__address c-locator-skeleton__bg"></div>
        </div>
        <div className="c-locator-list__schedule">
          <div className="c-locator-skeleton__text c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__hour c-locator-skeleton__bg"></div>
        </div>
      </li>
      <li className="c-locator-list__item c-locator-skeleton__item">
        <div className="c-locator-skeleton__info">
          <div className="c-locator-skeleton__name c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__name c-locator-skeleton__name--mobile c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__address c-locator-skeleton__bg"></div>
        </div>
        <div className="c-locator-list__schedule">
          <div className="c-locator-skeleton__text c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__hour c-locator-skeleton__bg"></div>
        </div>
      </li>
      <li className="c-locator-list__item c-locator-skeleton__item">
        <div className="c-locator-skeleton__info">
          <div className="c-locator-skeleton__name c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__name c-locator-skeleton__name--mobile c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__address c-locator-skeleton__bg"></div>
        </div>
        <div className="c-locator-list__schedule">
          <div className="c-locator-skeleton__text c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__hour c-locator-skeleton__bg"></div>
        </div>
      </li>
      <li className="c-locator-list__item c-locator-skeleton__item">
        <div className="c-locator-skeleton__info">
          <div className="c-locator-skeleton__name c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__name c-locator-skeleton__name--mobile c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__address c-locator-skeleton__bg"></div>
        </div>
        <div className="c-locator-list__schedule">
          <div className="c-locator-skeleton__text c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__hour c-locator-skeleton__bg"></div>
        </div>
      </li>
      <li className="c-locator-list__item c-locator-skeleton__item">
        <div className="c-locator-skeleton__info">
          <div className="c-locator-skeleton__name c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__name c-locator-skeleton__name--mobile c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__address c-locator-skeleton__bg"></div>
        </div>
        <div className="c-locator-list__schedule">
          <div className="c-locator-skeleton__text c-locator-skeleton__bg"></div>
          <div className="c-locator-skeleton__hour c-locator-skeleton__bg"></div>
        </div>
      </li>
    </ul>
  );
};
