import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleByNumber, handleErrorByNumber } from "../redux/search.slice";
import { LocationsSelect } from "./LocationsSelect";
import { StatesSelect } from "./StatesSelect";
// import { StreetInput } from "./StreetInput";

export const SearchForm = ({ className, onSubmit }) => {
  const byNumber = useSelector((state) => state.search.byNumber);
  const dispatch = useDispatch()

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (byNumber) {
      dispatch(handleByNumber(null))
      dispatch(handleErrorByNumber(false));
    }

    //Check before run onSubmit
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form onSubmit={handleSearchSubmit} className={`${className} c-branch-form`}>
      <StatesSelect />
      <LocationsSelect />
      {/* <StreetInput /> */}

      <div className="c-branch-form__submit-holder">
        <button type="submit" className="c-branch-form__submit c-rounded-btn c-rounded-btn--green">
          Buscar
        </button>
      </div>
    </form>
  );
};
