import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleByNumber, handleErrorByNumber, setFocusResult } from "../shared/redux/search.slice";

export const BranchStoreInfoPopup = () => {
  const focusResult = useSelector((state) => state.search.focusResult);
  const dispatch = useDispatch();
  const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const today = new Date();
  const day = days[today.getDay()];


  const handleCloseClick = () => {
    dispatch(setFocusResult(null));
    dispatch(handleByNumber(null));
    dispatch(handleErrorByNumber(false));
  };

  return !!focusResult ? (
    <div className="c-location-modal is-active">
      <div className="c-location-modal__holder o-scrollable o-align-middle">
        <div className="c-location-modal__aligner o-align-middle__aligner">

          <p className={`c-location-modal__text c-location-modal__status ${!focusResult.isOpen ? " c-location-modal__status--closed" : ""}`}>
            {focusResult.isOpen ? (
              <>
                Abierto
                <span class="c-location-modal__caption">Cierra a las <strong>{focusResult.closeDesc} hrs</strong></span>
              </>
            ) : (
              <>
                Cerrado
                <span class="c-location-modal__caption">Abre a las <strong>{focusResult.openDesc} hrs</strong></span>
              </>
            )}
          </p>

          <p className="c-location-modal__info">
            <strong className="c-location-modal__name">{focusResult.name} #{focusResult.number}</strong>
            <span className="c-location-modal__address c-location-modal__text">{focusResult.address}</span>
          </p>

          <p class="c-location-modal__subtitle">Teléfono</p>
          <p class="c-location-modal__text">{focusResult.phone ? focusResult.phone : '-'}</p>

          <p class="c-location-modal__subtitle">Horario de atención</p>
          <div class="c-location-modal__timetable c-location-timetable">
            <table class="c-location-timetable__table">
              <tbody>
                {focusResult.scheduleDesc && focusResult.scheduleDesc.map((desc, index) => (
                  <tr key={index} className={`${!desc.schedule ? 'is-closed' : ''}`}>
                    {desc.display === day ? <td className="current-day-blue">{desc.display}</td> : <td>{desc.display}</td>}
                    <td>{desc.schedule ? desc.schedule.map((schedule, index) => (
                      <>
                        {desc.display === day ? <span key={index} className="current-day-blue">{schedule}</span> : <span key={index}>{schedule}</span>}
                        {/* <span key={index}>{schedule}</span> */}
                        {index < desc.schedule.length - 1 && <br />}
                      </>
                    )) : 'Cerrado'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        onClick={handleCloseClick}
        type="button"
        className="c-location-modal__close c-close-btn">
        Ocultar información <span className="c-close-btn__cross"></span>
      </button>
    </div>
  ) : (
    <></>
  );
};