import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Search } from "./search/Search";
import { InitialSearch } from "./initialSearch/InitialSearch";
import { fetchLocations } from "./shared/redux/search.slice";
import { SEARCH_CLOSER_ROUTE, SEARCH_LINK_ROUTE, SEARCH_NUMBER_ROUTE, SEARCH_ROUTE, START_ROUTE } from "./shared/const/routes";
import { ClosePopupRoute } from "./shared/components/ClosePopupRoute";
import { SearchCloserBranchStores } from "./shared/components/SearchCloserBranchStores";
import { SearchByLink } from "./shared/components/SearchByLink";
import { SearchByNumber } from "./shared/components/SearchByNumber";

function App() {
  const dispatch = useDispatch();

  //Fetch locations
  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={SEARCH_CLOSER_ROUTE} component={SearchCloserBranchStores} />
      <Route exact path={SEARCH_LINK_ROUTE} component={SearchByLink} />
      <Route path={SEARCH_NUMBER_ROUTE} component={SearchByNumber} />
      <Route path={SEARCH_ROUTE} component={Search} />
      <Route path={START_ROUTE} component={InitialSearch} />
      <Route component={ClosePopupRoute} />
    </Switch>
  );
}

export default App;
