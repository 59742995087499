import { createSlice } from "@reduxjs/toolkit";

import client from "../api/http-client";
import { COUNTRYSIDES, START_STATE_SELECTED } from "../const/search";
import { trackFiltersEvent, trackSearchEvent } from "../utils/analytics";

const initialState = {
  result: [],
  allResults: [],
  hoverResult: null,
  focusResult: null,
  displayMap: true,
  locations: [],
  allLocations: [],
  countrysides: COUNTRYSIDES,
  loading: false,
  error: null,
  byNumber: null,
  byNumberError: false
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateLocations(state, action) {
      const key = action.payload ? action.payload : START_STATE_SELECTED;
      state.locations = state.allLocations[key.toLowerCase()];
    },
    updateResult(state, action) {
      state.result = action.payload ? action.payload : state.allResults;
    },

    //Location actions
    fetchLocationsStart(state, action) {
      state.loading = true;
      state.locations = [];
      state.allLocations = [];
    },
    fetchLocationsSuccess(state, action) {
      //Map all locations to lower case
      const allLocations = {};
      for (const locationName in action.payload) {
        allLocations[locationName.toLowerCase()] = action.payload[locationName];
      }

      state.loading = false;
      state.locations = allLocations[START_STATE_SELECTED.toLowerCase()];
      state.allLocations = allLocations;
      state.error = null;
    },
    fetchLocationsError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.locations = [];
      state.allLocations = [];
    },

    //Results actions
    setHoverResult(state, action) {
      state.hoverResult = action.payload;
    },
    setFocusResult(state, action) {
      state.focusResult = action.payload;
    },

    //Map
    setDisplayMap(state, action) {
      state.displayMap = action.payload;
    },

    //Search actions
    searchStart(state, action) {
      state.loading = true;
      state.error = null;
      state.hoverResult = null;
      state.focusResult = null;
    },
    searchSuccess(state, action) {
      state.loading = false;
      state.result = action.payload.result;
      state.allResults = action.payload.allResults;
      state.error = null;
      state.hoverResult = null;
      state.focusResult = null;
    },
    searchError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.result = [];
      state.allResults = [];
      state.hoverResult = null;
      state.focusResult = null;
    },
    // link by number
    handleByNumber(state, action) {
      state.byNumber = action.payload;
    },
    handleErrorByNumber(state, action) {
      state.byNumberError = action.payload;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsError,
  fetchLocationsSuccess,
  updateLocations,
  searchStart,
  searchSuccess,
  searchError,
  setHoverResult,
  setFocusResult,
  setDisplayMap,
  updateResult,
  handleByNumber,
  handleErrorByNumber
} = searchSlice.actions;

export const fetchLocations = () => {
  return async function (dispatch) {
    dispatch(fetchLocationsStart());
    try {
      const response = await client.get("/state_locations.php");
      dispatch(fetchLocationsSuccess(response.data));
    } catch (error) {
      dispatch(fetchLocationsError(error.message));
    }
  };
};

export const search = (filters) => {
  return async function (dispatch) {
    dispatch(searchStart());

    //Filter in memory filters
    const { isOpen, tickantel, uts, moneygram, more, redbrou, futbol, ...params } = filters;
    try {
      const response = await client.get("/search.php", { params });

      //Track event
      trackSearchEvent(filters, response?.data);

      //Trigger dispatch
      dispatch(
        searchSuccess({
          result: filter(response.data, filters),
          allResults: response.data,
        })
      );
    } catch (error) {
      dispatch(searchError(error.message));
    }
  };
};

export const filter = (items, filters) => {
  const result = items.filter((item) => {
    if (
      (filters.isOpen && !item.isOpen) ||
      (filters.tickantel && !item.tickantel) ||
      (filters.uts && !item.uts) ||
      (filters.moneygram && !item.moneygram) ||
      (filters.redbrou && !item.redbrou) ||
      (filters.futbol && !item.futbol) ||
      (filters.more && !item.more)
    ) {
      return false;
    }
    return true;
  });

  //Track filters
  trackFiltersEvent(filters, result);

  return result;
};

export default searchSlice.reducer;
