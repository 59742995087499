import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { EMPTY_ROUTE } from "../shared/const/routes";
import { clearFilters } from "../shared/redux/filters.slice";
import { closePopup } from "../shared/utils/dom";

export const SearchTop = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = (e) => {
    //close popup
    closePopup();

    //Clear search
    dispatch(clearFilters());

    //Clear hash
    history.push(EMPTY_ROUTE);
  };
  return (
    <div className="c-locator-header__group c-locator-header__top">
      <div className="c-locator-header__wrapper o-wrapper o-align-middle">
        <h2 className="c-locator-header__heading c-branch-search__heading o-align-middle__aligner">
          <span className="c-branch-search__heading-icon"></span>Buscador de locales
        </h2>
        <button onClick={handleClose} type="button" className="c-locator-header__close c-close-btn js-hide-app">
          Cerrar <span className="c-close-btn__cross"></span>
        </button>
      </div>
    </div>
  );
};
