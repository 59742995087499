import React from "react";
import { useDispatch } from "react-redux";
import { setLatLng } from "../shared/redux/filters.slice";

export const SearchGpsFilterEnabled = ({ onClear }) => {
  const dispatch = useDispatch();

  const handleClearLatLngClick = () => {
    dispatch(setLatLng({ latitude: "", longitude: "" }));

    if (onClear) {
      onClear();
    }
  };
  return (
    <div className="c-locator-list__location c-locator-location is-active">
      <div className="c-locator-location__wrapper o-wrapper">
        <div className="c-locator-location__holder">
          <p className="c-locator-location__text">
            <span className="c-locator-location__icon icon-target"></span>
            Mostrando locales más cerca a tu ubicación
          </p>
          <button type="button" className="c-locator-location__close c-close-btn" onClick={handleClearLatLngClick}>
            Borrar filtro <span className="c-close-btn__cross"></span>
          </button>
        </div>
      </div>
    </div>
  );
};
