// Return map bounds based on list of places
export const fitMapBounds = (map, maps, pins, ignoreZoom) => {
  const bounds = new maps.LatLngBounds();

  pins.forEach((pin) => {
    bounds.extend(new maps.LatLng(pin.latitude, pin.longitude));
  });

  // Fit map to bounds
  map.fitBounds(bounds);

  //Zom -1 to fit all markers
  if (!ignoreZoom) {
    map.setZoom(map.getZoom() - 1);
  }
};
