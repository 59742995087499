import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../api/http-client';
import { SEARCH_ROUTE } from '../const/routes';
import { setLocation, setState } from '../redux/filters.slice';
import { fetchLocations, handleByNumber, handleErrorByNumber, } from '../redux/search.slice';
import { showPopup } from '../utils/dom';

export const SearchByNumber = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { city, id } = props.match.params;

    // Text without accents and not spacing
    const cleanText = (text) => {
        const withoutAccents = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '');
        const notSpacing = withoutAccents.replace(/\s+/g, '');
        return notSpacing;
    }
    // Parse city and location
    const newCity = cleanText(city);

    // Get location then redirect to search
    dispatch(handleErrorByNumber(false));
    useEffect(() => {
        //Display popup
        showPopup();
        dispatch(fetchLocations());

        const getById = async (id) => {
            const response = await client.get("/search.php", { params: { number: parseInt(id) } });
            if (response.data.length === 1) {
                //Set filters
                if (newCity) {
                    dispatch(setState(newCity));
                }
                dispatch(setLocation(response.data[0].location));
                dispatch(handleByNumber({ id: id, city: newCity, location: response.data[0].location, data: response.data[0] }));
                // history.push(SEARCH_ROUTE);
                // setTimeout(() => {
                history.push(SEARCH_ROUTE);
                // }, 2000);
            } else {
                dispatch(handleByNumber({ id: id, city: newCity, data: response.data[0] }));
                // dispatch(searchError())
                setTimeout(() => {
                    history.push(SEARCH_ROUTE);
                }, 2000);
            }
        }
        getById(id);
    }, []);
    return (
        // <div />
        <div className='loaderr-container'><span className="loaderr"></span></div>
    )
}