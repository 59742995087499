import { configureStore } from "@reduxjs/toolkit";

import search from "./search.slice";
import filters from "./filters.slice";

const store = configureStore({
  reducer: { search, filters },
});

export default store;
