import React from "react";

export const SearchFilterOpenCheckbox = ({ hasResults, value, onChange, popupMode }) => {
  const componentClass = popupMode ? "popup" : "filters";
  return (
    <div className={`c-locator-${componentClass}__open c-locator-checkbox`}>
      <label className={`c-locator-checkbox__label ${!hasResults ? "is-disabled" : ""}`}>
        <input
          type="checkbox"
          name="abierto"
          className="c-locator-checkbox__input"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={!hasResults}
        />
        <span className={`c-locator-checkbox__text c-locator-checkbox__text--open c-locator-${componentClass}__text`}>
          Abierto
        </span>
        <span className="c-locator-checkbox__checkbox icon-tick"></span>
      </label>
    </div>
  );
};
