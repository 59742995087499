import React from "react";

export const BranchStoresPlaceholder = ({ message }) => {
  const _default = "No se encontraron resultados para tu búsqueda."
  const link = "¡Lo sentimos! El destino no fue encontrado. Si tienes dudas sobre tu solicitud por favor comunícate con atención al cliente al teléfono <a>27055555</a>. ¡Saludos!"

  if (message === "default") {
    return <div className="c-locator-list__placeholder">{message === "default" ? _default : link}</div>;
  } else {

  }
  return <div className="c-locator-list__placeholder">¡Lo sentimos! El destino no fue encontrado. <br /> Si tienes dudas sobre tu solicitud por favor comunícate con atención al cliente al teléfono <a href="tel:27055555" className="tel-link" target="_blank"> 27055555</a>. <br /> ¡Saludos!</div>;
};
