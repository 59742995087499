import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFutbol,
  setIsOpen,
  setMoneygram,
  setMore,
  setRedbrou,
  setTickantel,
  setUts,
  updateFiltersCount,
} from "../shared/redux/filters.slice";
import { SearchFilterOpenCheckbox } from "./SearchFilterOpenCheckbox";
import { SearchFilterServiceCheckbox } from "./SearchFilterServiceCheckbox";

export const SearchFiltersForm = ({ hasResults }) => {
  const filters = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  const handleIsOpenChange = (checked) => {
    dispatch(setIsOpen(checked));
    dispatch(updateFiltersCount());
  };
  const handleTickantelChange = (checked) => {
    dispatch(setTickantel(checked));
    dispatch(updateFiltersCount());
  };
  const handleUtsChange = (checked) => {
    dispatch(setUts(checked));
    dispatch(updateFiltersCount());
  };
  const handleMoneygranmChange = (checked) => {
    dispatch(setMoneygram(checked));
    dispatch(updateFiltersCount());
  };
  const handleMoreChange = (checked) => {
    dispatch(setMore(checked));
    dispatch(updateFiltersCount());
  };
  const handleRedbrouChange = (checked) => {
    dispatch(setRedbrou(checked));
    dispatch(updateFiltersCount());
  };
  const handleFutbolChange = (checked) => {
    dispatch(setFutbol(checked));
    dispatch(updateFiltersCount());
  };

  return (
    <div className="c-locator-filters__aligner o-align-middle__aligner">
      <div className="c-locator-filters__title c-locator-filters__text">Filtros</div>

      <SearchFilterOpenCheckbox hasResults={hasResults} onChange={handleIsOpenChange} value={filters.isOpen} />

      <div className="c-locator-filters__title c-locator-filters__text">Servicios</div>

      <SearchFilterServiceCheckbox
        title="TuCajero"
        img="images/locator/tucajero@2.png"
        imgWidth="94"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleRedbrouChange}
        value={filters.redbrou}
      />
      <SearchFilterServiceCheckbox
        title="Tickantel"
        img="images/locator/tickantel@2.png"
        imgWidth="76"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleTickantelChange}
        value={filters.tickantel}
      />
      <SearchFilterServiceCheckbox
        title="TuID"
        img="images/locator/tuid@2.png"
        imgWidth="62"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleUtsChange}
        value={filters.uts}
      />
      <SearchFilterServiceCheckbox
        title="More"
        img="images/locator/more@2.png"
        imgWidth="70"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleMoreChange}
        value={filters.more}
      />
      <SearchFilterServiceCheckbox
        title="MoneyGram"
        img="images/locator/moneygram@2.png"
        imgWidth="110"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleMoneygranmChange}
        value={filters.moneygram}
      />
      <SearchFilterServiceCheckbox
        title="Futbol"
        img="images/locator/futbol@2.png"
        imgWidth="85"
        imgHeight="32"
        hasResults={hasResults}
        onChange={handleFutbolChange}
        value={filters.futbol}
      />
    </div>
  );
};
