import React from "react";
import { BranchStoresListItem } from "./BranchStoresListItem";

export const BranchStoresList = ({ items }) => {
  return (
    <ul className="c-locator-list__list js-locator-list__list">
      {items.map((item) => (
        <BranchStoresListItem item={item} key={item.number} />
      ))}
    </ul>
  );
};
