import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, setState } from "../redux/filters.slice";
import { updateLocations } from "../redux/search.slice";

export const StatesSelect = () => {
  const countrysides = useSelector((state) => state.search.countrysides);
  const state = useSelector((state) => state.filters.state);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const value = e.target.value;

    //Locations
    dispatch(updateLocations(value));

    //Dispatch changes
    dispatch(setState(value));
    dispatch(setLocation(null));
  };

  return (
    <label className={`c-branch-form__group c-branch-form__group--select ${state ? "" : "is-placeholder"}`}>
      <select name="ciudad" className="c-branch-form__select" value={state} onChange={handleOnChange}>
        <option defaultValue disabled={!!state}>
          Seleccione la ciudad...
        </option>
        <option value="Montevideo">Montevideo</option>
        <optgroup label="Interior">
          {countrysides.map((state) => (
            <option key={state.value} value={state.value}>
              {state.value}
            </option>
          ))}
        </optgroup>
      </select>

      <span className="c-branch-form__label">Montevideo / Interior</span>
      <span className="c-branch-form__input">{state ? state : "Seleccione la ciudad..."}</span>
    </label>
  );
};
