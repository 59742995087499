import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SEARCH_ROUTE } from '../const/routes';
import { setUts, updateFiltersCount } from '../redux/filters.slice';
import { showPopup } from '../utils/dom';

export const SearchByLink = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // Get location then redirect to search
    useEffect(() => {
      setTimeout(() => {
        //Set filters
        dispatch(setUts(true));
        dispatch(updateFiltersCount());

        //Push search route
        history.push(SEARCH_ROUTE);

        //Display popup
        showPopup();
      }, 1000);
    }, []);
    return <div />
}
