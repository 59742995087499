import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { BranchStorePin } from "./BranchStorePin";
import { fitMapBounds } from "../shared/utils/map";
import { GpsPin } from "./GpsPin";
import { DEFAULT_MAPS_OPTIONS, MAP_STYLES } from "../shared/const/maps";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayMap } from "../shared/redux/search.slice";

//Flag to handle focus in or focus out
let focusedResult = false;

export const SearchMap = ({ items, gpsLatitude, gpsLongitude }) => {
  const focusResult = useSelector((state) => state.search.focusResult);
  const dispatch = useDispatch();
  const mapsRef = useRef({});

  const handleOnGoogleApiLoaded = ({ map, maps }) => {
    //Load map ref
    mapsRef.current = { map, maps };
  };

  useEffect(() => {
    if (mapsRef.current.map && mapsRef.current.maps) {
      //Check items length
      if (!items.length) {
        return;
      }

      //get bounds and fit it
      fitMapBounds(mapsRef.current.map, mapsRef.current.maps, items, gpsLatitude && gpsLongitude);
    }
  }, [items]);

  useEffect(() => {
    if (mapsRef.current.map) {
      //Check focus result
      if(focusResult && focusResult.latitude && focusResult.longitude){
        //Dispatch show map
        dispatch(setDisplayMap(true));

        //get bounds and fit it
        fitMapBounds(mapsRef.current.map, mapsRef.current.maps, [focusResult]);

        //Set flag to restore zoom
        focusedResult = true;
      } else if(focusedResult){
        //get bounds and fit it
        fitMapBounds(mapsRef.current.map, mapsRef.current.maps, items);

        //Set flag to restore zoom
        focusedResult = false;
      }
    }
  }, [focusResult]);

  return (
    <div className={focusResult ? "c-locator-map-container" : ""} style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: window.GOOGLE_MAPS_API_KEY }}
        defaultCenter={DEFAULT_MAPS_OPTIONS.center}
        defaultZoom={DEFAULT_MAPS_OPTIONS.zoom}
        options={{ 
          maxZoom: DEFAULT_MAPS_OPTIONS.maxZoom, 
          styles: MAP_STYLES, 
          zoomControlOptions: {
              style: 1,
              position: 1
            } 
          }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleOnGoogleApiLoaded}
      >
        {items &&
          items.map((item) => (
            <BranchStorePin 
              key={item.number} 
              item={item} 
              lat={+item.latitude} 
              lng={+item.longitude}/>
          ))}

        {gpsLatitude && gpsLongitude && <GpsPin lat={gpsLatitude} lng={gpsLongitude} />}
      </GoogleMapReact>
    </div>
  );
};
