import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SEARCH_ROUTE } from '../const/routes';
import { setLatLng } from '../redux/filters.slice';

export const SearchCloserBranchStores = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // Get location then redirect to search
    useEffect(() => {
        try {
            //Get current position
            navigator.geolocation.getCurrentPosition((position) => {
              //Load to global filters obj lat and lng
              dispatch(
                setLatLng({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                })
              );

               //Redirect to search
                history.push(SEARCH_ROUTE);
            }, handleCurrentPositionError);
        } catch (error) {
            //Ops geolocation not available
            handleCurrentPositionError();
        }
    }, []);

    const handleCurrentPositionError = () => {
        //Clear lat lng in global filters object
        dispatch(setLatLng({ latitude: "", longitude: "" }));

        //Redirect to search
        history.push(SEARCH_ROUTE);
      };
    return <div />
}
