import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { START_ROUTE } from "../shared/const/routes";

export const SearchMobileTop = () => {
  const filters = useSelector((state) => state.filters);
  const history = useHistory();

  const handleOnModifyClick = () => {
    history.push(START_ROUTE);
  };
  return (
    <>
      <div className="c-locator-search__scrollable c-locator-search__mobile o-scrollable o-scrollable--x o-align-middle">
        <div className="c-locator-search__aligner o-align-middle__aligner">
          <strong className="c-locator-header__subtitle c-locator-search__title">Buscando en</strong>
          <ul className="c-locator-search__list">
            {filters.latitude && filters.longitude ? (
              <li className="c-locator-search__item">
                <span className="c-locator-search__text">Locales más cerca a tu ubicación</span>
              </li>
            ) : (
            <>
              {filters.state && (
                <li className="c-locator-search__item">
                  <span className="c-locator-search__text">{filters.state}</span>
                </li>
              )}

              {filters.location && (
                <li className="c-locator-search__item">
                  <span className="c-locator-search__text">{filters.location}</span>
                </li>
              )}

              {filters.street && (
                <li className="c-locator-search__item">
                  <span className="c-locator-search__text">{filters.street}</span>
                </li>
              )}
            </>
            )}
          </ul>
        </div>
      </div>
      <div className="c-locator-search__btn-holder c-locator-search__mobile">
        <button type="button" className="c-locator-search__modify" onClick={handleOnModifyClick}>
          Modificar <span className="c-locator-search__modify-hidden">búsqueda</span>
        </button>
      </div>
    </>
  );
};
