import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import App from "./App";
import store from "./shared/redux/store";
import { InitialSearch } from "./initialSearch/InitialSearch";
import "./index.css";

const rootElement = document.getElementById("root");
const inlineSearchElement = document.getElementById("inline-search");

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  rootElement
);

//Control render of inline search
if (inlineSearchElement) {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <InitialSearch inlineMode="true" />
      </HashRouter>
    </Provider>,
    inlineSearchElement
  );
}