import React from "react";

export const SearchFilterServiceCheckbox = ({
  hasResults,
  value,
  title,
  img,
  imgWidth,
  imgHeight,
  onChange,
  popupMode,
}) => {
  const componentClass = popupMode ? "popup" : "filters";
  return (
    <div className={`c-locator-${componentClass}__service c-locator-checkbox`}>
      <label className={`c-locator-checkbox__label ${!hasResults ? "is-disabled" : ""}`} title={title}>
        <input
          type="checkbox"
          className="c-locator-checkbox__input"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={!hasResults}
        />
        <span className="c-locator-checkbox__checkbox icon-tick"></span>
        <img src={img} width={imgWidth} height={imgHeight} alt={title} className="c-locator-checkbox__img" />
      </label>
    </div>
  );
};
