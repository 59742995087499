export const closePopup = () => {
  const viewport = document.getElementsByClassName("js-viewport")[0];
  const appLocator = document.getElementsByClassName("c-locator-app")[0];
  if (viewport) {
    viewport.classList.remove("u-mask");
  }
  if (appLocator) {
    appLocator.classList.remove("is-active");
  }

  //Trigger resize
  window.dispatchEvent(new Event("resize"));
};

export const showPopup = () => {
  const viewport = document.getElementsByClassName("js-viewport")[0];
  const appLocator = document.getElementsByClassName("c-locator-app")[0];
  if (viewport) {
    viewport.classList.add("u-mask");
  }
  if (appLocator) {
    appLocator.classList.add("is-active");
  }
};

export const isPopupVisible = () => {
  const appLocator = document.getElementsByClassName("c-locator-app")[0];
  return appLocator && appLocator.classList.contains("is-active");
};
