import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFocusResult, setHoverResult } from "../shared/redux/search.slice";

export const BranchStoresListItem = ({ item }) => {
  const hoverItem = useSelector((state) => state.search.hoverResult);
  const dispatch = useDispatch();

  const handleMouseOver = () => {
    dispatch(setHoverResult(item));
  };
  const handleMouseOut = () => {
    dispatch(setHoverResult(null));
  };
  const handleClick = () => {
    dispatch(setFocusResult(item));
  };

  return (
    <li
      className={`c-locator-list__item ${hoverItem?.number === item.number ? "is-focus" : ""}`}
      data-open={item.isOpen}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      <p className="c-locator-list__info">
        <strong className="c-locator-list__name">
          {item.name} #{item.number}
        </strong>
        <span className="c-locator-list__address">{item.address}</span>
      </p>

      <p className="c-locator-list__schedule">
        {item.isOpen ? (
          <>
            Cierra a las <strong className="c-locator-list__hour">{item.closeDesc} hrs</strong>
          </>
        ) : (
            <>
              Abre a las <strong className="c-locator-list__hour">{item.openDesc} hrs</strong>
            </>
          )}
      </p>
    </li>
  );
};
