import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloserBranchStoresButton } from "../shared/components/CloserBranchStoresButton";
import { SearchForm } from "../shared/components/SearchForm";
import { filter, handleErrorByNumber, search, searchError, setDisplayMap, setFocusResult, updateResult } from "../shared/redux/search.slice";
import { BranchStoresList } from "./BranchStoresList";
import { BranchStoresSkeletonLoader } from "./BranchStoresSkeletonLoader";
import { SearchGpsFilterEnabled } from "./SearchGpsFilterEnabled";
import { SearchMobileTop } from "./SearchMobileTop";
import { SearchTop } from "./SearchTop";
import { SearchMap } from "./SearchMap";
import { SearchFiltersForm } from "./SearchFiltersForm";
import { SearchFiltersPopup } from "./SearchFiltersPopup";
import { SearchMenuMobile } from "./SearchMenuMobile";
import { BranchStoresPlaceholder } from "./BranchStoresPlaceholder";
import { BranchStoreInfoPopup } from "./BranchStoreInfoPopup";
// import { setLocation, setState } from "../shared/redux/filters.slice";

export const Search = () => {
  const filters = useSelector((state) => state.filters);
  const loading = useSelector((state) => state.search.loading);
  const result = useSelector((state) => state.search.result);
  const allResults = useSelector((state) => state.search.allResults);
  const allLocations = useSelector((state) => state.search.allLocations);
  const displayMap = useSelector((state) => state.search.displayMap);
  const byNumber = useSelector((state) => state.search.byNumber);
  // const focusResult = useSelector((state) => state.search.focusResult);
  const [showFiltersPopup, setShowFiltersPopup] = useState(false);
  const [errorByNumber, setErrorByNumber] = useState(false);
  const dispatch = useDispatch();

  // set error message in search for link
  useEffect(() => {
    if (errorByNumber) {
      setErrorByNumber(false)
    }
  }, [])

  //At start run first search
  useEffect(() => {
    //Dispatch show map
    dispatch(setDisplayMap(true));

    //Search on enter
    dispatch(search(filters));


  }, [filters.latitude, filters.longitude]);

  //Filter when memory filters changes
  useEffect(() => {
    //Check all results
    if (allResults.length) {
      //Filter result
      const filteredResults = filter(allResults, filters);

      //Dispatch show map
      dispatch(setDisplayMap(true));

      //Dispatch results changed
      dispatch(updateResult(filteredResults));

    }
  }, [
    //In memort filters change
    filters.isOpen,
    filters.tickantel,
    filters.uts,
    filters.moneygram,
    filters.more,
    filters.redbrou,
    filters.futbol,
  ]);

  // Handle events
  const handleOnSearch = () => {
    //Dispatch fetch
    dispatch(search(filters));
  };


  // show shop by number
  if (byNumber && result.length > 0) {
    const store = result.find(objeto => objeto.number === parseInt(byNumber.id));
    const city = byNumber ? allLocations[byNumber.city] : null;
    if (store && city) {
      dispatch(setFocusResult(store));
      if (errorByNumber) {
        setErrorByNumber(false)
      }
    } else {
      // set error message
      setErrorByNumber(true)
      dispatch(handleErrorByNumber(true))
      dispatch(searchError())
    }
  }


  return (
    <div className={`c-locator-app__app is-active ${loading ? "is-loading" : ""}`}>
      <div className="c-locator-app__header c-locator-header">
        <div className="c-locator-header__holder">
          <SearchTop />

          <div className="c-locator-header__group c-locator-header__search c-locator-search">
            <div className="c-locator-header__wrapper c-locator-search__wrapper o-wrapper">
              <SearchMobileTop />
              <SearchForm className="c-locator-search__form c-locator-search__desktop" onSubmit={handleOnSearch} />
            </div>
          </div>

          <div className="c-locator-header__group c-locator-header__filters c-locator-filters">
            <div className="c-locator-filters__mobile c-locator-header__wrapper o-wrapper o-align-middle">
              <SearchMenuMobile setShowFiltersPopup={setShowFiltersPopup} />
            </div>
            <div className="c-locator-filters__desktop c-locator-header__wrapper o-wrapper o-align-middle">
              <SearchFiltersForm hasResults={allResults.length > 0} />
            </div>
          </div>
        </div>
      </div>

      <div className="c-locator-app__body">
        <div className={`c-locator-app__section c-locator-app__list ${!displayMap ? "is-active" : ""}`}>
          <div className="c-locator-list">
            {filters.latitude && filters.longitude && <SearchGpsFilterEnabled />}
            <BranchStoresSkeletonLoader />
            <BranchStoresList items={result} />
            {result.length === 0 && !loading && !byNumber && <BranchStoresPlaceholder message={"default"} />}
            {errorByNumber && !loading ? <BranchStoresPlaceholder message={"¡Lo sentimos! El destino no fue encontrado. Si tienes dudas sobre tu solicitud por favor comunícate con atención al cliente al teléfono 27055555. ¡Saludos!"} /> : ''}
          </div>
        </div>

        <div
          className={`c-locator-app__section c-locator-app__map c-locator-map u-bg-light ${displayMap ? "is-active" : ""
            }`}
        >
          <SearchMap
            items={result}
            gpsLatitude={filters.latitude}
            gpsLongitude={filters.longitude} />
        </div>

        {!filters.latitude && !filters.longitude && <CloserBranchStoresButton className="c-locator-app__location" />}
        <BranchStoreInfoPopup />
      </div>

      {showFiltersPopup && <SearchFiltersPopup setShowPopup={setShowFiltersPopup} />}
    </div>
  );
};
