export const START_STATE_SELECTED = "Montevideo";

export const COUNTRYSIDES = [
  {
    value: "Artigas",
  },
  {
    value: "Canelones",
  },
  {
    value: "Cerro Largo",
  },
  {
    value: "Colonia",
  },
  {
    value: "Durazno",
  },
  {
    value: "Flores",
  },
  {
    value: "Florida",
  },
  {
    value: "Lavalleja",
  },
  {
    value: "Maldonado",
  },
  {
    value: "Paysandú",
  },
  {
    value: "Río Negro",
  },
  {
    value: "Rivera",
  },
  {
    value: "Rocha",
  },
  {
    value: "Salto",
  },
  {
    value: "San José",
  },
  {
    value: "Soriano",
  },
  {
    value: "Tacuarembó",
  },
  {
    value: "Treinta y tres",
  },
];
